.contact-info {
  padding: 0 30px 0px 0;
}
.contactIcons {
  color: #fff;
}

h2.contact-title {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px;
}

.contact-info p {
  color: #ececec;
}

ul.contact-info {
  margin-top: 30px;
}

ul.contact-info li {
  margin-bottom: 22px;
}

ul.contact-info span {
  /* font-size: 20px; */
  line-height: 26px;
}
ul.contact-info li {
  display: flex;
  width: 100%;
}
.info-left {
  width: 10%;
}

.info-right {
  width: 90%;
}

.info-left i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  color: #ffffff;
}

.info-right h4 {
  color: #fff;
  font-size: 18px;
}

.officeName,
.officeAddress {
  padding-left: 0.1em;
  color: #fff;
  font-size: 18px;
}

.officeName {
  font-weight: 500;
}
.contact-page .info-left i {
  color: #fe846f;
}
@media only screen and (max-width: 767px) {
  .contact-form {
    padding: 30px;
  }
  .contact-form:before {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .officeName,
  .officeAddress {
    font-size: 12px;
  }
}
.officeName {
  width: 30%;
}
.officeDetails {
  width: 100%;
  display: flex;
  margin-bottom: 1em;
}
.officeAddress {
  width: 70%;
}
