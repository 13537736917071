nav {
  z-index: 990;
  position: fixed;
  top: 0em;
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 33px;
  transition: 0.3s ease;
  /* background: #fff; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  /* background-color: blue; */
}

.navBackground {
  background: #fff;
}

.brand {
  color: #000;
  z-index: 999;
  width: 25%;
  height: 80%;
  overflow: hidden;
  text-transform: uppercase;
  text-decoration: none;
}

.brand img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.navigationItems {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
}

.navigationItems li {
  cursor: pointer;
  /* position: relative; */
  /* margin-left: 30px; */
  transition: 0.3s ease;
}

.navLinkContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.navLinkParent :hover .navLinkdwn {
  color: white;
}

.navLinkdwn {
  color: #fff;
  /* rgb(3 96 251 / 89%); */
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  /* rgb(3 96 251 / 89%); */
}
/* rgba(3, 96, 251, 0.3); */
.navLink {
  /* rgb(0, 70, 190); */
  color: #fff;
  /* rgb(0, 70, 190); */
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
}
.nvLink {
  color: rgb(3 96 251 / 89%);
}

.navigationItems li:before {
  /* content: "";
    position: absolute;
    background: white;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 50%;
    border-radius: 10%;
    transition: 0.4s ease-out; */
}

.navigationItems li:hover::before {
  /* width: 80%;
  left: 5%;
  right: 5%; */
}
.navLinkParent {
  display: flex;
  position: relative;
}
.navLinkParent :before {
  content: "";
  position: absolute;
  background: rgb(3 96 251 / 89%);
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 5%;
  border-radius: 10%;
  transition: 0.2s ease-out;
}
.navLinkParent :hover::before {
  width: 80%;
  left: 5%;
  right: 5%;
}

.menuBtn {
  display: none;
  z-index: 999;
  transition: 0.3s ease-out;
  color: rgb(3 96 251 / 89%);
}
.menuBtn:hover {
  cursor: pointer;
}
.closeBtn {
  color: #fff;
  background-color: #328fd4;
}
.subMenuCard {
  /* position: absolute;
  padding: 10px;
  background: #fff;
  min-width: auto;
  max-width: 200%;
  top: 100%;
  left: 0;
  height: auto;
  transition: 0.3s ease;
  font-family: Source Sans Pro, sans-serif; */

  position: absolute;
  background: #0c1923;
  top: 100%;
  display: block;
  float: left;
  min-width: 258px;
  /* width: auto; */
  padding: 1em 10%;
  /* margin: 1em 2em 1em 2em; */
  text-align: left;
  color: #fff !important;
  left: 0%;
  font-size: 12px;
  border-top: 2px solid #000000;
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-width: 1160px) {
  nav {
    height: 5em;
  }
  .navOpened {
    min-height: 100vh;
    overflow: auto;
    height: max-content;
    animation: slideMenuFromTop 0.4s ease-out;
    background-color: #163247;
  }

  .brand {
    position: absolute;
    height: 3em;
    top: 1.5em;
  }
  .menuBtn {
    position: absolute;
    top: 1.5em;
    right: 2em;
    display: inline-block;
    transition: 0.3s ease-out;
  }
  .navigationItems {
    display: none;
  }
  .navLinkParent :before {
    display: none;
  }
  .mobileNavItems {
    margin-top: 4em;
    padding: 5em 5em 5em 3em;

    /* overflow-y: scroll; */
    position: absolute;
    width: 100%;
    /* height: 100%; */
    /* min-height: 100vh; */
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    /* animation: slideMenuFromTop 0.8s ease-out; */
    padding-top: 5em;
    /* background-color: #264653; */
    /* background-image: linear-gradient(
      86.3deg,
      rgba(0, 119, 182, 1) 3.6%,
      rgba(8, 24, 68, 1) 87.6%
    ); */
    /* background: #16222a; 
    background: linear-gradient(
      to right,
      #3a6073,
      #16222a
    );  */
  }
  .navLink {
    letter-spacing: 3px;
    color: #fff;
  }
  .dropdownIcon {
    color: #577684;
  }
  .dropdownIcon:hover {
    color: white;
  }
  .navLinkContainer {
    display: block;
  }
  @keyframes slideMenuFromTop {
    0% {
      top: -100%;
    }
    100% {
      top: 0%;
    }
  }

  .mobileNavItems li {
    color: #fff;
    cursor: pointer;
    line-height: 4em;
    position: relative;
  }
  @keyframes slideMenuFromLe {
    0% {
      width: 0%;
    }
    50% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  .mobileNavItems li::after {
    content: "";
    position: absolute;
    height: 1px;
    bottom: 0;
    background-color: #fff;
    left: 0;
    width: 100%;
    animation: slideMenuFromLe 0.8s ease-out;
  }

  .mobileNavItems li::before {
    display: none;
  }

  .mobileNavItems li:before {
    content: "";
    position: absolute;
    background: #fff;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 50%;
    border-radius: 10%;
    transition: 0.4s ease-out;
  }

  .mobileNavItems li:hover::before {
    width: 80%;
    left: 5%;
    right: 5%;
  }
}
