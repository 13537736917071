.login-div {
  min-width: 260px;
  height: 310px;
  padding: 40px 35px 35px 35px;
  border-radius: 10px;
  background: #ecf0f3;
  box-shadow: -3px -3px 7px #ffffff, 3px 3px 5px lightblue;
  margin-top: 1em;
  margin-right: 5em;
}
.logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 
    /* logo shadow */ 0px 0px 2px #5f5f5f,
    /* offset */ 0px 0px 0px 5px #ecf0f3, /*bottom right */ 8px 8px 15px #a7aaaf,
    /* top left */ -8px -8px 15px #ffffff;

  width: 100px;
  height: 100px;
  background: #e8e8e8;
  border-radius: 100%;
}
.memberName {
  text-align: center;
  font-size: 18px;
  padding-top: 24px;
  letter-spacing: 0.5px;
  color: #0fa3b1;
}
.sub-title {
  text-align: center;
  font-size: 15px;
  padding-top: 7px;
  letter-spacing: 3px;
  font-weight: 450;
}
.zone {
  text-align: center;
  font-size: 11px;
  padding-top: 1px;
  letter-spacing: 3px;
}
.memberProfession {
  text-align: center;
  font-size: 14px;
  padding-top: 3px;
  letter-spacing: 2px;
  color: #036972;
}
