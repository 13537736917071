ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  /* background: #151414; */
  box-shadow: 5px 5px 15px rgb(0 0 0 / 0.2);
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.locIcon {
  color: rgb(33 150 243) !important;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  margin-left: 2em;
  padding-left: 15px;
}
.cta-text h4 {
  color: #757575;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #000;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #757575;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #757575;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: rgb(33 150 243);
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: rgb(6, 102, 180);
}
.footer-widget ul li a {
  color: rgb(33 150 243);
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  /* background: #2e2e2e; */
  border: 1px solid #fff;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.6);
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: rgb(33 150 243);
  padding: 13px 20px;
  border: 1px solid #fff;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: rgb(33 150 243);
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: rgb(33 150 243);
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
@media (max-width: 870px) {
  .footer-section {
    background: #304146 !important;
  }
  .cta-text h4 {
    color: #fff;
  }
  .footer-widget-heading h3 {
    color: #fff;
  }
  .cta-text span {
    color: #c9c4c4;
  }
  .footer-widget-heading span {
    color: #c9c4c4;
  }
  .footer-social-icon {
    margin-bottom: 1em;
  }
  .footer-social-icon span {
    color: #fff;
  }
  .footer-text p {
    color: #c9c4c4;
  }
  .footer-widget ul li a {
    color: #dcd6d6;
  }
}
