.overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  padding: 110px 0;
}
.section-bg::before {
  background-image: linear-gradient(
    to right,
    rgba(84, 96, 234, 0.9),
    #1bb7ea /* rgba(84, 96, 234, 0.9) */
  );
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: "";
  position: absolute;
  left: 0;
}
.section-bg {
  background-size: cover;
  position: relative;
  background-position: left;
  z-index: 0;
  padding: 0;
  min-height: auto;
  overflow: hidden;
}
.contact-form {
  position: relative;
  padding: 45px 0 45px 60px;
}

.contact-form:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
  background: #ececec;
  box-shadow: 10px 40px 40px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  right: auto;
  width: 100vw;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .contact-form {
    padding: 30px;
  }
  .contact-form:before {
    width: 100%;
  }
}
