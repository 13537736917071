.menuListItemContainer {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
}
.listItemContainer {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}
.listItemContainer:nth-last-child(1) {
  border-bottom: none;
}
.menuListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 4em;
}
.menuListItem:nth-last-child(1) {
  border-bottom: none;
}
.menuListItem :hover {
  color: #00aed1;
  /* rgb(255, 86, 86); */
}

.menuListItemLink {
  text-decoration: none;
}
.nvLink {
  float: right;
}
.navLinkdwn2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 12px;
}
.navLinkdwn2Hover {
  position: absolute;
}
.navLink2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: rgba(152, 151, 151, 0.974);
}
.menuListItemLinkTxt {
  color: rgba(152, 151, 151, 0.974);
}
.menuListItemLinkTxt :hover {
  color: #00aed1;
}
.subMenuCard2 {
  position: absolute;
  background: #0c1923;
  /* top: 0.5em; */
  display: block;
  float: left;
  min-width: 258px;
  /* width: auto; */
  padding: 1em 10%;
  /* margin: 1em 2em 1em 2em; */
  text-align: left;
  color: #fff !important;
  left: 100%;
  font-size: 12px;
  border-top: 2px solid #000000;
  text-decoration: none;
  text-transform: uppercase;
  /* animation: slideMenuFromLeft 0.8s ease-out; */
}
@keyframes slideMenuFromLeft {
  0% {
    left: -50%;
  }
  100% {
    left: 0%;
  }
}

.subMenuCard {
  position: absolute;
  background: #0c1923;
  top: 100%;
  margin-top: 0.6em;
  display: block;
  float: left;
  min-width: 258px;
  /* width: auto; */
  padding: 1em 10%;
  /* margin: 1em 2em 1em 2em; */
  text-align: left;
  color: #fff !important;
  left: 0%;
  font-size: 12px;
  border-top: 0.5em solid #0fa3b1;
  border-top-left-radius: 0.2em;
  border-top-right-radius: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
  /* animation: slideMenuFromLeft 0.5s ease-out; */
}

@media (max-width: 1160px) {
  .subMenuCard {
    position: static;
    min-width: 336px;
    /* box-shadow: 5px 0px 5px #0fa3b1 inset; */
    /* transition: 2s ease; */
    /* background: white; */
    /* background: linear-gradient(to right, #3a6073, #16222a); */
    background-color: transparent;
    border-top: none;
    /* box-shadow: 0 0 50px #ccc inset; */
    margin-top: 0em;
  }
  .subMenuCard2 {
    position: static;
    /* box-shadow: 5px 0px 5px #0fa3b1 inset; */
    /* transition: 2s ease; */
    /* background: white; */
    /* background: linear-gradient(to right, #3a6073, #16222a); */
    background-color: transparent;
    border-top: none;
    /* border-left: 2px solid #000000; */
    /* box-shadow: 0 0 50px #ccc inset; */
  }
  .menuListItemLinkTxt {
    color: white;
  }
  .listItemContainer {
    background: #164364;
    padding-left: 8px;
    border-bottom: 1px solid #163247;
  }
  .navLink2 {
    color: white;
  }
  .menuListItem {
    height: 4em;
    /* padding: 1em; */
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
  }
  .menuListItem:first-child {
    /* border-top: 1px solid hsla(0, 0%, 100%, 0.25); */
  }
}
