.admissionContainer::before {
  /* background-image: linear-gradient(to right, #000, #2b2a2a); */
  background: #000;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  position: absolute;
  left: 0;
}
.admissionContainer {
  background-size: cover;
  position: relative;
  background-position: left;
  z-index: 0;
  padding: 0;
  min-height: auto;
  overflow: hidden;
}
