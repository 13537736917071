.tourGalleryContainer {
  /* background: #24404a; */
  background: linear-gradient(to right, #2c5364, #203a43, #0f2027);
  /* background: -webkit-linear-gradient(to right, #00416a, #e4e5e6); */
  /* background: linear-gradient(to right, #00416a, #e4e5e6); */
  padding-block: 8em;
}
.tourGalleryVidContainer {
  margin-inline: auto;
  /* margin-top: 6em; */
  width: 600px;
  height: 600%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
}
.tourGalleryVideo {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* clip-path: circle(0.8% at 0 50%); */
  clip-path: circle(150% at 50%);
  transition: 2s ease;
  transition-property: clip-path;
}
.headOfficeTitle {
  /* background: -webkit-linear-gradient(#eee, #333); */
  font-weight: bold !important;
  text-align: center;
  font-size: 18px;
  font-family: "Trebuchet MS", Verdana, sans-serif !important;
  background: linear-gradient(#eee, #333);
}
.hedOfficeTitle p {
  font-size: 18px;
  background: linear-gradient(#eee, #333);
  background-clip: text;
  /* text-fill-color: transparent; */
}
@media (max-width: 650px) {
  .tourGalleryVidContainer {
    width: auto;
  }
}
