.hero {
  background-color: #f8f8f8;
  overflow: hidden;
  margin-top: 5em;
  position: relative;
}
.hero::before {
  position: absolute;
  clip-path: polygon(75% 0, 100% 0, 100% 100%, 56% 100%);
  background-color: #1bb7ea;
  content: "";
  width: 100%;
  height: 100%;
}
.heading h1 {
  color: #0fa0d0;
  font-size: 35px;
  padding-left: 7%;
  margin-top: 35px;
}
.aboutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 35px auto;
}
.hero-content {
  flex: 1;
  width: 600px;
  margin: 0px 25px;
  animation: fadeInUp 2s ease;
}
.hero-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}
.hero-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 40px;
  color: #666;
}
.hero-content button {
  display: inline-block;
  background-color: #1bb7ea;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  transition: 0.3 ease;
}
.hero-content button:hover {
  background-color: #0fa0d0;
  transform: scale(1.1);
}
.hero-image {
  flex: 1;
  margin: auto;
  width: 600px;
  animation: fadeInRight 2s ease;
  z-index: 1;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.hero-image img {
  width: 100%;
}

@media screen and (max-width: 1250px) {
  .heading h1 {
    font-size: 45px;
    margin-top: 2em;
    text-align: center;
    padding-left: 0;
  }
  .hero {
    margin: 0px;
  }
  .aboutContainer {
    width: 100%;
    flex-direction: column;
    margin: 0px;
    padding: 0px 40px;
  }
  .hero-content {
    width: 100%;
    margin: 35px 0px;
  }
  .hero-content h2 {
    font-size: 30px;
  }
  .hero-content p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .hero-content button {
    font-size: 16px;
    padding: 8px 16px;
  }
  .hero-image {
    width: 100%;
  }
  .hero-image img {
    width: 100%;
  }
  .hero::before {
    display: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
