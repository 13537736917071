.galleryWrap {
  /* margin-top: 5em; */
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin-inline: auto;
}
.galleryHeadingContainer {
  display: flex;
  justify-content: center;
  color: #fff;
}
.galleryHeading {
  margin-top: 3em !important;
  margin-bottom: 3em !important;
  font-weight: bold !important;
  font-size: 20px !important;
  /* font-family: "Trebuchet MS", Verdana, sans-serif !important; */
  width: 80%;
  margin-inline: auto;
  text-align: left;

  letter-spacing: 1.5px !important;
  font-family: Karla, sans-serif !important;
  position: relative;
}
.galleryHeading::after {
  position: absolute;
  content: "";
  height: 2px;
  background-color: white;
  bottom: -0.5em;
  left: 0;
  width: 8%;
}

.galleryWrap .single {
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.galleryWrap .single img {
  max-width: 100%;
  transition: all 0.5s ease-in-out;
}

.galleryWrap .single img:hover {
  transform: scale(1.5);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
