.admissionFormContainer {
  background: #2d3e3f;
  width: 450px;
  padding-bottom: 20px;
  margin-top: 0em;
  margin-bottom: 4em;
  padding: 130px 5px 20px 5px;
  height: 100%;
  margin-inline: auto;
}

.fl {
  float: left;
  width: 110px;
  line-height: 35px;
}

.fontLabel {
  color: white;
}

.fr {
  /* float: right; */
}

.clr {
  clear: both;
}

.boxContainer {
  width: 360px;
  height: 35px;
  margin-top: 10px;
  font-family: verdana;
  font-size: 12px;
}

.textBoxClass {
  height: 35px;
  width: 190px;
  border: none;
  padding-left: 20px;
}

.new {
  float: left;
}

.iconBox {
  height: 35px;
  width: 40px;
  line-height: 38px;
  text-align: center;
  background: rgb(33 150 243);
}

.radio {
  color: white;
  background: #2d3e3f;
  line-height: 38px;
}

.terms {
  line-height: 35px;
  text-align: center;
  background: #2d3e3f;
  color: white;
}

.submitBtn {
  float: right;
  border: none;
  color: white;
  width: 110px;
  height: 35px;
  background: rgb(33 150 243);
  text-transform: uppercase;
  cursor: pointer;
}
